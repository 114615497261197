










import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
@Component
export default class SvgIcon extends Vue {
  @Prop() private readonly className!: any
  @Prop() private readonly icon!: any

  private get svgClass(): any {
    return `svg-icon${this.className ? ` ${this.className}` : ''}`
  }

  private get iconName(): any {
    return `#svg-${this.icon}`
  }

  @Watch('icon', { immediate: true })
  private onIconChange(val): void {
    if (!val) return
    require(`@/assets/svg/${val}.svg`)
  }
}
